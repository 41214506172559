<template>
  <div>
    <el-scrollbar wrap-style="padding: 10px; max-height: 400px;">
      <!-- <dialog-component
        :visible="dialog"
        containerMaxWidth="70%"
        @close="closeDialog"
        v-loading="loading"
        title="Validate New Domain"
      > -->
        <el-row class="validate-domain-dialog">
          <el-col>
            <span class="fw-bold">Enter Your Domain</span><br/>
            <el-input
              v-model="form.domain"
              :disabled="form.isValid"
              @keyup.enter.native="handleEnterKey"
              placeholder="Enter Your domain (e.g., esings.io)"
              width="100%"
            >
              <el-button
                slot="append"
                @click="checkIsValid"
                icon="el-icon-right"
                :disabled="!form.domain || form.isValid"
              ></el-button>
            </el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col v-if="form.isValid" class="permissions-container">
            <el-card shadow="never">
              <div class="information-text">
                Please log in to your domain and add the necessary permissions
                to your DNS.
              </div>
              <div class="cards-container">
                <el-card
                  shadow="never"
                  class="child-card"
                  v-for="record in dnsRecords"
                  :key="record.name"
                >
                  <ul>
                    <li>
                      <b>Name</b>: {{ record.name }}
                      <span class="copy-url"
                        ><i
                          class="el-icon-copy-document"
                          @click="copyURL(record.name)"
                        ></i
                      ></span>
                    </li>
                    <li><b>Type</b>: {{ record.type }}</li>
                    <li>
                      <b>Host</b>: {{ record.host
                      }}<span class="copy-url"
                        ><i
                          class="el-icon-copy-document"
                          @click="copyURL(record.host)"
                        ></i
                      ></span>
                    </li>
                    <li>
                      <b>Data/Value</b>: {{ record.data
                      }}<span class="copy-url"
                        ><i
                          class="el-icon-copy-document"
                          @click="copyURL(record.data)"
                        ></i
                      ></span>
                    </li>
                  </ul>
                </el-card>
              </div>
              <div class="validate-button-div">
                <p>
                  <el-button type="primary" @click="validateDomain()"
                    >validate</el-button
                  >
                </p>
              </div>
            </el-card>
          </el-col></el-row
        >
      <!-- </dialog-component> -->
    </el-scrollbar>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "email-integration",
  data() {
    return {
      dialog: true,
      loading: false,
      form: {
        domain: "",
        isValid: false,
      },
      dnsRecords: [
        {
          name: "notify",
          type: "cname",
          host: `notify.test.com`,
          data: "u27997328.wl079.sendgrid.net",
        },
        {
          name: "s1._domainkey",
          type: "cname",
          host: `s1._domainkey.test.com`,
          data: "s1.domainkey.u27997328.wl079.sendgrid.net",
        },
        {
          name: "s2._domainkey",
          type: "cname",
          host: `s2._domainkey.test.com`,
          data: "s2.domainkey.u27997328.wl079.sendgrid.net",
        },
      ],
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.resetForm();
      this.dialog = false;
    },
    copyURL(field) {
      navigator.clipboard.writeText(field);
      this.$message({
        message: "Text Copied!",
        type: "success",
      });
    },
    checkIsValid() {
      if (!this.form.domain) {
        return this.$message.warning("Invalid Domain");
      }
      this.form.isValid = true;
    },
    handleEnterKey(event) {
      if (event.keyCode === 13) {
        this.checkIsValid();
      }
    },
    resetForm() {
      this.form = {
        domain: "",
        isValid: false,
      };
    },
    async validateDomain() {
      this.loading = true;
      let data = {
        domain_id: "20228735",
      };
      let accessToken = "kf-92c29adc74a1459e9700d8ddb88c420d";
      let config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      let url = "https://notify-dev.kodefast.com/service/domain/validate";
      await axios
        .post(url, data, config)
        .then((response) => {
          this.loading = false;
          if (response?.data?.data?.valid) {
            this.dialog = false;
            this.$message.success("domain successfully registered");
          } else {
            this.$message.warning(
              "domain validation failed.Please add the the necessary permissions to your DNS"
            );
          }
          return response;
        })
        .catch((error) => {
          this.loading = false;
          console.error("An error occurred:", error.response.data.error_data);
          console.error("An error occurred:", error.data.message);
        });
    },
  },
};
</script>

<style scoped>
.permissions-container {
  margin-top: 20px;
}

.cards-container {
  margin-top: 20px;
  display: flex;
}

.child-card {
  margin-right: 15px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 8px;
}
li b {
  width: 80px;
}
.copy-url {
  margin: 5px;
  cursor: pointer;
  color: #409eff;
}
.information-text {
  text-align: center;
}
.validate-button-div {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.validate-button {
  width: 150px;
}
.validate-domain-dialog {
height: 100px;
display: flex;
justify-content: center;
align-content: center;
margin-top: 2%;
}
</style>
